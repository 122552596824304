<template>
  <page-main
    title="Position"
    nextText="Responsibilities"
    :buttonTabIndex="`${tiPosOffset + 4}`"
    eventName="nav"
    @nav="nav('/WorkResponsibilities/' + key)"
    @save="save()"
  >
    <template v-slot:aside>
      Core information about your work experiences: what you did, for how long,
      and whether you had any direct reports.
      <ul>
        <li>
          For each work record, we will focus on one position. If you held
          multiple roles at the same company, list each position as separate
          work record.
        </li>
        <li>
          Titles are typically self-explanatory: Business Analyst, Software
          Engineering Intern, Customer Service Representative, etc.
        </li>
        <li>
          Roles succinctly describe the type of position you held, providing
          more context and detail beyond your title.
        </li>
        <li>
          Direct reports are people whose work you oversaw and who you managed
          as part of your regular duties.
        </li>
      </ul>
    </template>
    <template v-slot:nav>
      <v-system-bar color="accent" height="5" class="mt-4"></v-system-bar>
      <modal cardTitle="Example" ctaText="Need an example?">
        <ul class="font-italic">
          <li class="mb-4">
            <span class="font-weight-bold">Example #1:</span>
            <ul>
              <li>
                <span class="font-weight-bold">Title:</span>
                Mechanical Engineer
              </li>
              <li>
                <span class="font-weight-bold">Role:</span>
                HVAC Systems & Maintenance
              </li>
            </ul>
          </li>
          <li class="mb-4">
            <span class="font-weight-bold">Example #2:</span>
            <ul>
              <li>
                <span class="font-weight-bold">Title:</span>
                Manager
              </li>
              <li>
                <span class="font-weight-bold">Role:</span>
                3rd Shift Operations
              </li>
            </ul>
          </li>
          <li class="mb-4">
            <span class="font-weight-bold">Example #3:</span>
            <ul>
              <li>
                <span class="font-weight-bold">Title:</span>
                Director
              </li>
              <li>
                <span class="font-weight-bold">Role:</span>
                Strategy and Operations
              </li>
            </ul>
          </li>
          <li class="mb-4">
            <span class="font-weight-bold">Example #4:</span>
            <ul>
              <li>
                <span class="font-weight-bold">Title:</span>
                Research Scientist
              </li>
              <li>
                <span class="font-weight-bold">Role:</span>
                Machine Learning Data Operations
              </li>
            </ul>
          </li>
          <li class="mb-4">
            <span class="font-weight-bold">Example #5:</span>
            <ul>
              <li>
                <span class="font-weight-bold">Title:</span>
                Bartender
              </li>
              <li>
                <span class="font-weight-bold">Role:</span>
                Weekend Services
              </li>
            </ul>
          </li>
        </ul>
      </modal>
      <modal cardTitle="Tips" ctaText="Need a few tips?" :accentButton="true">
        <r-tip-container :tips="tips"></r-tip-container>
      </modal>
    </template>
    <v-container>
      <v-row>
        <v-col cols="12" class="r-col">
          <r-dual-list
            label="Position you held:"
            labelOne="Title"
            labelTwo="Role"
            buttonLabel="Add Position"
            v-model="positions"
            tabindex="2"
            autofocus
            dataNameOne="title"
            dataNameTwo="role"
            keyName="key"
            :maxSelection="1"
          ></r-dual-list>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-switch
            v-model="workingCurrently"
            label="Are you currently working here?"
            tabindex="3"
          ></r-switch>
        </v-col>
        <v-col cols="12" class="r-col">
          <month-date-select
            label="Start Date"
            :value.sync="startDate"
            tabindex="4"
          ></month-date-select>
        </v-col>
        <v-col cols="12" class="r-col">
          <month-date-select
            v-if="!workingCurrently"
            label="End Date"
            :value.sync="endDate"
            tabindex="5"
          ></month-date-select>
          <r-text-field
            v-else
            v-model="currentDisplay"
            disabled
            class="max-h-82"
          ></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-switch
            v-model="hasDRs"
            label="Did you have any direct reports?"
            :tabindex="`${tiPosOffset + 2}`"
          ></r-switch>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="directReports"
            label="How many direct reports?"
            :disabled="!hasDRs"
            type="number"
            :tabindex="`${tiPosOffset + 3}`"
          ></r-text-field>
        </v-col>
      </v-row>
    </v-container>
  </page-main>
</template>

<script>
import * as uuid from "../utility/guid";
import { mapFields } from "vuex-map-fields";
import { mapGetters, mapMutations, mapActions } from "vuex";
// @ is an alias to /src
export default {
  name: "WorkRoles",
  data: () => ({
    hasDRs: false,
  }),
  mounted() {
    let pageKey = this.$route.params.key;
    if (this.key != pageKey) {
      if (!this.doesKeyExist(pageKey)) {
        this.createEntry({ key: pageKey });
      }
      this.key = pageKey;
    }

    if (this.positions.length == 0) {
      this.positions.push({ title: "", role: "", key: uuid.gen() });
    }

    this.hasDRs = this.directReports > 0;
  },
  methods: {
    save() {
      if (!this.hasDRs) {
        this.directReports = 0;
      }

      this.saveWork();
    },
    ...mapMutations("work", ["createEntry", "setResponsibilitiesDescription"]),
    ...mapActions("work", ["saveWork"]),
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
  },
  computed: {
    ...mapGetters("education", ["doesKeyExist"]),
    ...mapFields("work", {
      key: "key",
      companyName: "workGeneral.companyName",
      positions: "workRole.positions",
      directReports: "workResponsibilities.directReports",
      description: "workResponsibilities.description",
      workingCurrently: "workGeneral.workingCurrently",
      startDate: "workGeneral.startDate",
      endDate: "workGeneral.endDate",
    }),
    displayDR: function () {
      return this.hasDRs ? "Yes" : "No";
    },
    tiPosOffset() {
      return this.positions.length * 3 + 1; // last tab index in the positions section
    },
    tips() {
      return [
        "Titles are typically self-explanatory, with examples like Business Analyst, Software Engineer, Manager, or Supervisor. Roles will succinctly describe what you do to help you provide more context and detail beyond your title, and at times come in the form of your team’s name.",
        "If you had multiple roles in the same company, list each position out as separate work record. This means that in some cases, you may have several work records with the same company name, but each work record will have different positions along with differing information on work responsibilities and accomplishments.",
        "Make sure if you are adding multiple records with the same company name, you keep the name spelling consistent.",
      ];
    },
  },
};
</script>

<style>
/* The month-date-select control is 82 px high */
.max-h-82 {
  max-height: 82px;
}
</style>
